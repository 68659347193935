import { StyledComponent } from '@emotion/styled';
import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Stack,
  SxProps,
  Typography,
  styled,
  svgIconClasses,
  typographyClasses,
} from '@mui/material';
import cls from 'classnames';
import { Children, ElementType, ReactElement, ReactNode } from 'react';

import { UtilityProps } from '../../utils/prop';
import { open } from './ToastRenderer';
import { ToastAction, ToastActionProps } from './internal/ToastAction';

export enum ToastVariant {
  NEUTRAL = 'neutral',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

type Props = {
  /** The variant of the component */
  variant?: ToastVariant | `${ToastVariant}`;

  /** The content of the component */
  children: ReactNode;

  /** The array of actions of the component */
  actions?: ReactElement<ToastActionProps, typeof ToastAction>[];

  /** The icon of the component */
  icon?: ElementType | null;

  /** The classname of the component */
  className?: string;

  /** If `true`, the component will wrap its content when overflowed */
  wrap?: boolean;

  /** After duration of time, this notice will disappear.(seconds) */
  duration?: number;
} & UtilityProps;

const classes = {
  wrap: 'Toast__wrap',
  actionContainer: 'Toast__action-container',
};

/** This component inherits [MUI Box's API](https://mui.com/material-ui/api/box/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/story/elements-messages--toast-story)
 */
const Toast = styled(
  ({
    variant: _variant = ToastVariant.NEUTRAL,
    children,
    wrap = true,
    icon = InfoOutlined,
    actions = [],
    className,
    duration: _duration,
    ...props
  }: Props) => {
    const Icon = icon;

    return (
      <Box {...props} className={cls(className, wrap && classes.wrap)}>
        {Icon && <Icon />}

        <Typography variant="caption" noWrap={!wrap}>
          {children}
        </Typography>

        {!!actions.length && (
          <Stack className={classes.actionContainer} direction="row" gap="6px">
            {Children.toArray(actions)}
          </Stack>
        )}
      </Box>
    );
  },
)(
  ({
    actions = [],
    icon = InfoOutlined,
    variant = ToastVariant.NEUTRAL,
    theme: {
      palette,
      shape: { borderRadius },
      spacing,
    },
  }) => {
    const cols = ['1fr'];
    if (icon !== null) {
      cols.unshift('16px');
    }

    if (actions.length) {
      cols.push('max-content');
    }

    const variant2BgColor: Record<ToastVariant, `#${string}`> = {
      danger: palette.neon._100,
      info: palette.mercury._100,
      neutral: palette.order,
      warning: palette.bromine._100,
      success: palette.citrus._100,
    };

    const variant2BorderColor: Record<ToastVariant, `#${string}`> = {
      danger: palette.neon._500,
      info: palette.mercury._500,
      neutral: palette.chaos,
      warning: palette.bromine._500,
      success: palette.citrus._500,
    };

    const variant2TextColor: Record<ToastVariant, `#${string}`> = {
      danger: palette.neon._700,
      info: palette.mercury._700,
      neutral: palette.chaos,
      warning: palette.bromine._700,
      success: palette.citrus._700,
    };

    return {
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: cols.join(' '),
      alignItems: 'center',
      gap: spacing(2),

      width: '360px',
      padding: spacing(4),

      borderRadius,
      border: `1px solid ${variant2BorderColor[variant]}`,

      backgroundColor: variant2BgColor[variant],
      color: variant2TextColor[variant],
      [`& .${classes.actionContainer} > button`]: {
        color: `${variant2TextColor[variant]} !important`,
      },

      [`&.${classes.wrap}`]: {
        alignItems: 'start',

        [`.${typographyClasses.root}`]: {
          overflowWrap: 'anywhere',
        },
      },

      [`.${svgIconClasses.root}`]: {
        fontSize: '16px',
        color: variant2BorderColor[variant],
      },
    } satisfies SxProps;
  },
) as StyledComponent<Props> & { ToastAction: typeof ToastAction };

Toast.ToastAction = ToastAction;
Toast.displayName = 'Toast';

export { Toast, ToastAction, open as openToast, classes as toastClasses };
