/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChevronRightRounded, CloseRounded } from '@mui/icons-material';
import {
  Box,
  Chip,
  ChipProps,
  SxProps,
  Typography,
  chipClasses,
  styled,
  svgIconClasses,
  useTheme,
} from '@mui/material';
import cls from 'classnames';
import { isNil, merge } from 'lodash';
import { ElementType, ReactElement, SyntheticEvent, cloneElement, forwardRef } from 'react';

import { useInputValue } from '../../hooks/useInputValue';
import { AnsaradaColor, getContrastTextColor, withAnsaradaColor } from '../../utils/color';
import { ConditionalCompound, MergeObject } from '../../utils/conditionalCompound';
import { UtilityProps } from '../../utils/prop';
import { Number, numberClasses } from './internal/Number';
import { LozengeVariant } from './types';

type VariantProps =
  | {
      /** The variant of the component */
      variant?: LozengeVariant.LABEL | `${LozengeVariant.LABEL}`;
    }
  | {
      /** The variant of the component */
      variant: LozengeVariant.STATUS | `${LozengeVariant.STATUS}`;

      /** The color of component */
      color?: AnsaradaColor;

      /** The tally of the component */
      number?: number | string;

      /** The icon of the component */
      icon?: ReactElement;

      /** If defined, a cross icon will be shown, triggering this callback when clicking */
      onDelete?: ChipProps['onDelete'];
    }
  | {
      /** The variant of the component */
      variant: LozengeVariant.LINK | `${LozengeVariant.LINK}`;

      /** The color of component */
      color?: AnsaradaColor;

      /** The link of the component to point to */
      link?: string;

      /** The component to render the link */
      linkComponent?: ElementType;

      /** The function runs when the component gets clicked */
      onClick?: (e: SyntheticEvent) => void;

      /** The icon of the component */
      icon?: ReactElement;

      /** The tally of the component */
      number?: number | string;
    }
  | {
      /** The variant of the component */
      variant: LozengeVariant.FILTER | `${LozengeVariant.FILTER}`;

      /** The color of component */
      color?: AnsaradaColor;

      /** The value of the component */
      value?: boolean;

      /** The default value of the component */
      defaultValue?: boolean;

      /** The function runs when value of the component changes */
      onChange?: (v: boolean) => void;

      /** The icon of the component */
      icon?: ReactElement;

      /** The tally of the component */
      number?: number | string;

      /** If defined, a cross icon will be shown when hovering, triggering this callback when clicking */
      onDelete?: ChipProps['onDelete'];
    }
  | {
      /** The variant of the component */
      variant: LozengeVariant.DROPDOWN | `${LozengeVariant.DROPDOWN}`;

      /** The function runs when the dropdown of the component opens */
      onOpen: (e: any) => void;

      /** The color of component */
      color?: AnsaradaColor;

      /** The tally of the component */
      number?: number | string;
    };

type MergedVariants = MergeObject<VariantProps>;

type CommonProps = {
  /** The label of the component */
  label: string;

  /** The max width of the component, `label` will be truncated in order for it to match this property */
  maxWidth?: number | string;

  /** By default, Lozenge will forcefully use variant `500` from Ansarada color palette, `true` to disable that behavior  */
  disableColorForcing?: boolean;
} & UtilityProps;

type ExternalProps = CommonProps &
  ConditionalCompound<VariantProps> &
  Omit<ChipProps, keyof CommonProps | keyof MergedVariants>;

type InternalProps = CommonProps &
  MergedVariants &
  Omit<ChipProps, keyof CommonProps | keyof MergedVariants>;

const classes = {
  LABEL: 'Lozenge--label',
  STATUS: 'Lozenge--status',
  LINK: 'Lozenge--link',
  FILTER: 'Lozenge--filter',
  DROPDOWN: 'Lozenge--dropdown',
  DISMISSIBLE: 'Lozenge--dismissible',

  showNumber: 'Lozenge--show-number',
  selected: 'Lozenge--selected',

  labelElement: 'Lozenge__label',
  iconWrapper: 'Lozenge__icon-wrapper',
  numberPrefix: 'Lozenge__number-prefix',
  box: 'MuiBox-root',
};

/** This component inherits [MUI Chip's API](https://mui.com/material-ui/api/chip/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-lozenge--documentation)
 * ---
 * @componentName Lozenge
 * @abc Lozenge
 */
const BaseLozenge = styled(
  forwardRef<HTMLDivElement, ExternalProps>((props: ExternalProps, ref) => {
    const {
      color,
      label,
      variant = LozengeVariant.LABEL,
      onClick,
      value,
      onChange,
      number,
      maxWidth: _maxWidth,
      defaultValue,
      link,
      linkComponent,
      icon,
      onOpen,
      className,
      disableColorForcing: _disableColorForcing,
      ...rest
    } = props as InternalProps;

    const { palette } = useTheme();

    const isShowingNumber = !isNil(number);

    const { handleInternalValueChange, internalValue } = useInputValue({
      value,
      defaultValue,
      onChange,
    });

    return (
      <Chip
        {...merge(
          {
            size: 'small',
            clickable: variant !== LozengeVariant.STATUS && variant !== LozengeVariant.LABEL,
            onClick: (e: any) => {
              onClick?.(e);
              onOpen?.(e);
              handleInternalValueChange(!internalValue);
            },
            deleteIcon: <CloseRounded />,
            label: (
              <>
                {(variant === LozengeVariant.LINK ||
                  variant === LozengeVariant.FILTER ||
                  variant === LozengeVariant.STATUS) &&
                  icon && (
                    <Box className={classes.iconWrapper}>
                      {cloneElement(icon, {
                        fontSize: 'small',
                      })}
                    </Box>
                  )}

                {isShowingNumber &&
                  (variant === LozengeVariant.LINK || variant === LozengeVariant.DROPDOWN) && (
                    <Typography component="span" variant="caption" className={classes.numberPrefix}>
                      {number}
                    </Typography>
                  )}

                <Typography className={classes.labelElement} variant="caption" noWrap>
                  {label}
                </Typography>

                {isShowingNumber &&
                  variant !== LozengeVariant.LINK &&
                  variant !== LozengeVariant.DROPDOWN && (
                    <Number backgroundColor={color} variant={variant}>
                      {number}
                    </Number>
                  )}

                {variant === LozengeVariant.DROPDOWN && (
                  <Box sx={{ height: '20px', color: palette._grey._700 }}>
                    <ChevronRightRounded fontSize="medium" sx={{ rotate: '90deg' }} />
                  </Box>
                )}
              </>
            ),
            component: linkComponent,
            href: link,
            to: link,
          } satisfies Partial<ChipProps> & { component?: ElementType; href?: string; to?: string },
          rest,
          {
            className: cls(
              className,
              classes[variant],
              isShowingNumber && classes.showNumber,
              internalValue && classes.selected,
            ),
          },
        )}
        ref={ref}
      />
    );
  }),
)(({ theme: { palette, spacing }, ...props }) => {
  const { color, maxWidth } = props as InternalProps;

  return {
    '&': {
      boxShadow: `inset 0px 0px 0px 1px ${color ?? palette._grey._400}`,
      backgroundColor: 'transparent',
      textDecoration: 'none !important',
      padding: '0px',
      height: '20px',

      [`& .${classes.iconWrapper}`]: {
        paddingLeft: spacing(2),
        height: '13px',
        fontSize: '13px',

        [`.${svgIconClasses.root}`]: {
          transform: 'translateY(-1px)',
        },
      },

      [`& .${chipClasses.label}`]: {
        display: 'flex',
        alignItems: 'center',
        gap: spacing(2),
        padding: '0px',
        color: palette._grey._600,

        [`.${numberClasses.root}`]: {
          color: color ? palette._grey._700 : palette._grey._600,
          paddingInline: spacing(2),
          maxWidth,
        },
      },

      [`&.${classes.selected}:not(:where(.${classes.DROPDOWN}, .${classes.LINK}))`]: {
        [`&.${classes.FILTER}`]: {
          backgroundColor: 'transparent',
          boxShadow: `inset 0px 0px 0px 1px ${color ?? palette._grey._400}`,

          [`& .${chipClasses.deleteIcon}`]: {
            color: color ?? palette._grey._600,
          },

          [`& .${numberClasses.root}`]: {
            backgroundColor: `${color ?? palette.mercury._300} !important`,
          },

          [`.${classes.labelElement}`]: {
            color: `${color ?? palette.mercury._300} !important`,
          },

          [`.${classes.iconWrapper}`]: {
            [`.${svgIconClasses.root}`]: {
              color: `${color ?? palette.mercury._300} !important`,
            },
          },
        },
      },

      [`&:where(.${classes.FILTER}, .${classes.STATUS})`]: {
        [`&:has(.${numberClasses.root}):has(.${chipClasses.deleteIcon})`]: {
          [`& .${chipClasses.deleteIcon}`]: {
            display: 'none',
          },

          ':hover': {
            [`& .${chipClasses.deleteIcon}`]: {
              display: 'unset',
            },

            [`& .${numberClasses.root}`]: {
              display: 'none',
            },
          },
        },
      },

      [`&.${classes.LABEL}`]: {
        [`& .${chipClasses.label}`]: {
          paddingInline: spacing(2),

          [`.${numberClasses.root}`]: {
            color: palette.chaos,
            fontWeight: 500,
          },

          [`.${classes.labelElement}`]: {
            fontWeight: 500,
            color: palette._grey._600,
          },
        },
      },

      [`&.${classes.STATUS}`]: {
        [`& .${chipClasses.deleteIcon}`]: {
          color: color ?? palette._grey._600,
          width: 14,
          height: 14,
          marginRight: '3px',
          ':hover': {
            backgroundColor: palette._grey._100,
            borderRadius: 50,
          },
        },

        [`& .${chipClasses.label}`]: {
          paddingInline: spacing(2),

          [`.${numberClasses.root}`]: {
            color: getContrastTextColor(color ?? palette.mercury._300),
            fontWeight: 500,
          },

          [`.${classes.labelElement}`]: {
            fontWeight: 500,
            color: color ?? palette._grey._700,
          },
        },

        [`.${classes.iconWrapper}`]: {
          paddingLeft: '0px',
          [`.${svgIconClasses.root}`]: {
            color: color ?? palette._grey._700,
          },
        },
      },

      [`&.${classes.FILTER}`]: {
        backgroundColor: color ?? palette.mercury._300,
        boxShadow: 'unset',

        [`& .${chipClasses.deleteIcon}`]: {
          color: getContrastTextColor(color ?? palette._grey._600),
          width: 14,
          height: 14,
          marginRight: '3px',

          ':hover': {
            backgroundColor: palette._grey._100,
            borderRadius: 50,
          },
        },

        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: `inset 0px 0px 0px 1px ${color ?? palette._grey._400}`,

          [`& .${chipClasses.deleteIcon}`]: {
            color: color ?? palette._grey._600,
          },

          [`& .${numberClasses.root}`]: {
            backgroundColor: `${color ?? palette.mercury._300} !important`,
          },

          [`.${classes.labelElement}`]: {
            color: `${color ?? palette.mercury._300} !important`,
          },

          [`.${classes.iconWrapper}`]: {
            [`.${svgIconClasses.root}`]: {
              color: `${color ?? palette.mercury._300} !important`,
            },
          },
        },

        [`.${classes.iconWrapper}`]: {
          paddingLeft: '0px',
          [`.${svgIconClasses.root}`]: {
            color: getContrastTextColor(color ?? palette.mercury._300),
          },
        },

        [`& .${chipClasses.label}`]: {
          color: palette.chaos,
          paddingInline: '8px',

          [`.${numberClasses.root}`]: {
            color: palette.chaos,
          },

          [`.${classes.labelElement}`]: {
            color: getContrastTextColor(color ?? palette.mercury._300),
          },
        },
      },

      [`&.${classes.DROPDOWN}`]: {
        backgroundColor: palette._grey._400,
        boxShadow: 'unset',

        '&:hover': {
          boxShadow: `inset 0px 0px 0px 1px ${palette._grey._700}`,
        },

        [`& .${chipClasses.label}`]: {
          color: palette.chaos,
          gap: '3.75px',
          paddingLeft: spacing(2),

          [`.${numberClasses.root}`]: {
            color: palette.chaos,
            paddingLeft: spacing(2),
            paddingRight: '0px',
          },
        },
      },

      [`&.${classes.LINK}`]: {
        boxShadow: 'unset',
        '&:hover': {
          backgroundColor: palette._grey._100,
        },

        [`& .${chipClasses.label}`]: {
          gap: '3.75px',
          paddingRight: spacing(2),

          [`&:not(&:has(.${classes.iconWrapper}))`]: {
            [`.${classes.numberPrefix}`]: {
              paddingLeft: spacing(2),
            },
          },

          [`&:not(:has(:where(.${classes.iconWrapper}, .${classes.numberPrefix})))`]: {
            paddingLeft: spacing(2),
          },

          [`.${classes.numberPrefix}, .${classes.labelElement}`]: {
            color: palette._grey._700,
          },
        },

        [`.${classes.iconWrapper}`]: {
          [`.${svgIconClasses.root}`]: {
            color: palette._grey._700,
          },
        },
      },

      [`&.${classes.showNumber}`]: {
        [`&.${classes.LINK}`]: {
          [`.${chipClasses.label}`]: {
            gap: '3.75px',
          },

          [`.${classes.iconWrapper}`]: {
            marginRight: '2.25px',
          },
        },

        [`& .${chipClasses.label}`]: {
          paddingRight: spacing(2),
          gap: '6px',

          [`.${numberClasses.root}`]: {
            paddingRight: spacing(1),
            paddingLeft: spacing(1),
          },
        },

        [`&.${classes.DROPDOWN}`]: {
          paddingLeft: spacing(2),

          [`& .${chipClasses.label}`]: {
            [`.${numberClasses.root}`]: {
              paddingRight: '0px',
            },
          },
        },
      },
    } satisfies SxProps,
  };
});

const Lozenge = withAnsaradaColor(BaseLozenge, {
  // @ts-expect-error color is a legit field
  colorFieldKeys: ['color'],
  forceColorVariant: '_500',
});

export { Lozenge, LozengeVariant, classes as lozengeClasses };
