import type {NormalizedCacheObject} from '@apollo/client'
import {ApolloClient, InMemoryCache, createHttpLink, from} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'

import {fragmentRegistry} from './fragmentRegistry'

type CreateClientConfig = {
  getAccessToken: () => Promise<string>
  uri: string
}

let apolloClient: ApolloClient<NormalizedCacheObject>

export function getOrCreateClient({uri, getAccessToken}: CreateClientConfig) {
  if (apolloClient) return apolloClient

  const httpLink = createHttpLink({uri})

  const httpAuthLink = setContext(async ({context}) => {
    const accessToken = await getAccessToken()

    return {
      headers: {
        ...context?.headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    }
  })

  const client = new ApolloClient({
    name: 'Identity SPA',
    version: '1.0.0',
    link: from([httpAuthLink, httpLink]),
    cache: new InMemoryCache({
      fragments: fragmentRegistry,
      typePolicies: {
        UserProfile: {
          keyFields: [],
        },
      },
    }),
    connectToDevTools: true,
  })

  apolloClient = client
  return client
}
