import {gql, useSuspenseQuery} from '@apollo/client'
import {withAuthenticationRequired} from '@auth0/auth0-react'
import {Box, Typography} from '@mui/material'
import {Form, Formik, type FormikHelpers} from 'formik'
import * as React from 'react'

import {DynamicForm} from '~/components/dynamic-form'
import {ProfileFormInputs} from '~/components/form-element'
import {Header} from '~/components/header'
import {Progress} from '~/components/progress'
import {MessageSection} from '~/components/sections/message-section'
import {ProfileFormSection} from '~/components/sections/profile-form-section'
import {defaultRequiredFields} from '~/constants/shared'
import {toast} from '~/lib/toast'
import {useUpdateProfileMutation} from '~/mutations/useUpdateProfileMutation'
import type {FormValues} from '~/types'
import type {ProfileRouteQuery} from '~/types/api'
import {isErrorWithFormValue, mapUserProfileInfo} from '~/utils/profile'

const PROFILE_ROUTE_QUERY = gql`
  query ProfileRouteQuery {
    me {
      id
      email
      firstName
      lastName
      phoneNumber
      companyName
      termsAndConditionsAccepted
      isSso
      signUpCompleted
      jobTitle {
        groupName
        internalId
        name
      }
      location {
        internalId
        name
      }
    }
  }
`

export const RouteComponent = withAuthenticationRequired(
  () => {
    const {data} = useSuspenseQuery<ProfileRouteQuery>(PROFILE_ROUTE_QUERY)
    const profileInfo = data.me

    if (!profileInfo) {
      throw new Error('Must be logged in')
    }
    const [updateProfileInfo, {loading: updatingProfile}] =
      useUpdateProfileMutation()

    const userName = `${profileInfo.firstName} ${profileInfo.lastName}`
    const initialValues = mapUserProfileInfo(profileInfo)

    const saveProfile = (
      formValues: typeof initialValues,
      {setErrors, setSubmitting}: FormikHelpers<typeof initialValues>,
    ) => {
      updateProfileInfo({
        variables: {
          input: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            phoneNumber: formValues.phoneNumber,
            locationId: formValues.locationId,
            jobTitleId: formValues.jobTitleId,
            companyName: formValues.companyName,
          },
        },
        onCompleted: () => {
          toast.success('Saved successfully')
          setSubmitting(false)
        },
        onError: ex => {
          if (isErrorWithFormValue(ex)) {
            setErrors(ex.errors)
          }
          setSubmitting(false)
        },
      })
    }

    return (
      <>
        <Header userName={userName} productName="Dash" />
        <Box sx={{my: 2, mx: 'auto', p: 5, maxWidth: '480px'}}>
          <DynamicForm
            initialValues={initialValues}
            fields={defaultRequiredFields}
          >
            {({validationSchema, values}) => (
              <Formik
                initialValues={values}
                onSubmit={saveProfile}
                validationSchema={validationSchema}
              >
                {({isValid}) => {
                  return (
                    <Form>
                      <ProfileFormSection
                        title="Edit profile"
                        description={
                          <Box>
                            {values.isSso ? (
                              <Typography component="span" variant="body2">
                                Your name and password are managed by your
                                organization
                              </Typography>
                            ) : (
                              <>
                                <Typography
                                  component="span"
                                  variant="body2"
                                >{`You're updating your profile `}</Typography>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  data-ansarada-ccd
                                >
                                  {profileInfo.email}
                                </Typography>
                              </>
                            )}
                          </Box>
                        }
                        submitButtonText="Save"
                        isSubmitting={updatingProfile}
                        isDisableSubmit={!isValid}
                      >
                        <ProfileFormInputs
                          profileValues={values as unknown as FormValues}
                          fieldsConfig={{
                            hideTermsAndConditions: true,
                          }}
                        />
                      </ProfileFormSection>
                    </Form>
                  )
                }}
              </Formik>
            )}
          </DynamicForm>
        </Box>
      </>
    )
  },
  {
    onRedirecting: () => <MessageSection title={<Progress />} />,
  },
)

export const LoadingState = () => {
  return <MessageSection title={<Progress />} />
}
