import { StyledComponent } from '@emotion/styled';
import { SxProps, Typography, styled } from '@mui/material';
import cls from 'classnames';
import { PropsWithChildren } from 'react';

import { LozengeVariant } from '../types';

type Props = PropsWithChildren<{
  backgroundColor?: string;
  variant: `${LozengeVariant}`;
  className?: string;
}>;

const classes = {
  root: `Lozenge__number-root`,
  filterLozenge: 'Lozenge__number--filter',
};

const Number = styled(
  ({ backgroundColor: _backgroundColor, variant, className, ...props }: Props) => {
    return (
      <Typography
        component="span"
        variant="caption"
        className={cls(
          className,
          classes.root,
          variant === LozengeVariant.FILTER && classes.filterLozenge,
        )}
        {...props}
      />
    );
  },
)(({ backgroundColor, theme: { spacing, palette } }) => {
  return {
    '&': {
      backgroundColor: backgroundColor || palette._grey._400,
      color: palette.chaos,
      textAlign: 'center',
      borderRadius: '10px',
      minWidth: '16px',
      padding: `0px ${spacing(1)}`,
      marginRight: '-5px',
      height: '14px',

      [`&.${classes.filterLozenge}`]: {
        backgroundColor: palette.order,
      },
    },
  } satisfies SxProps;
}) as StyledComponent<Props>;

export { Number, classes as numberClasses };
