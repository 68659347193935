import {gql, useMutation} from '@apollo/client'

import type {
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from '~/types/api'

const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfileMutation($input: UpdateMyProfileInput!) {
    updateMyProfile(input: $input) {
      userProfile {
        id
        email
        firstName
        lastName
        phoneNumber
        companyName
        termsAndConditionsAccepted
        isSso
        signUpCompleted
        jobTitle {
          groupName
          internalId
          name
        }
        location {
          internalId
          name
        }
      }
    }
  }
`

export const useUpdateProfileMutation = () => {
  return useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
    UPDATE_PROFILE_MUTATION,
  )
}
