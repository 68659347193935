import {Progress} from '~/components/progress'
import {MessageSection} from '~/components/sections/message-section'
import {useUpdateProfile} from '~/hook/use-update-profile'
import type {CompleteProfileDto} from '~/types'

interface Props {
  assetCallbackUrl: string
  signupToken: string
}

export const SignupComplete = ({assetCallbackUrl, signupToken}: Props) => {
  useUpdateProfile({
    variables: {
      signupToken,
      isEmailVerified: true,
    } as CompleteProfileDto,
    options: {
      onSuccess: () => {
        window.location.href = assetCallbackUrl
      },
    },
  })

  return <MessageSection title={<Progress />} />
}
