import {
  BackdropProps,
  Divider,
  PopoverProps as MuiPopoverProps,
  Paper,
  PaperProps,
  Popover,
  SxProps,
  useTheme,
} from '@mui/material';
import { merge } from 'lodash';
import { bindTrigger } from 'material-ui-popup-state';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import {
  ElementRef,
  FC,
  Fragment,
  MouseEventHandler,
  PropsWithoutRef,
  ReactElement,
  ReactNode,
  useId,
} from 'react';

import * as uiColorsV1 from '@ansarada/colors/lib/design-system-colors/v1';

import { UtilityProps } from '../../utils/prop';
import { SlotConfigProps, useSlot } from '../../utils/slot';
import { Item } from './internal/Item';
import { Group } from './slot/Group';
import { MenuDirection, MenuPlacement, getOriginFromPlacementAndDirection } from './utils';

type MuiPopoverKey = keyof Pick<MuiPopoverProps, 'disablePortal'>;

type StaticProps = {
  displayStatic: true;
  renderAnchor?: never;
  stopPropagation?: never;
} & Partial<Record<MuiPopoverKey, never>>;

type PopoverProps = {
  displayStatic?: false | undefined;
  stopPropagation?: boolean;
  renderAnchor: (props: ReturnType<typeof bindTrigger>) => ReactElement;
} & Pick<MuiPopoverProps, MuiPopoverKey>;

type BaseProps = {
  /** If `true`, the component will close when clicking on one of the items  */
  closeOnItemClick?: boolean;

  /** The placement of the component */
  placement?: MenuPlacement | `${MenuPlacement}`;

  /** The direction of the component */
  direction?: MenuDirection | `${MenuDirection}`;

  /** The `props` object of `Backdrop` of the component */
  BackdropProps?: PropsWithoutRef<Partial<BackdropProps>>;

  /**  Content of the component */
  children?: ReactNode;
} & SlotConfigProps<'groups'> &
  UtilityProps &
  (StaticProps | PopoverProps);

type Props = BaseProps & Omit<PaperProps, keyof BaseProps>;

/** This component inherits [MUI Paper's API](https://mui.com/material-ui/api/paper/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-menu-props--documentation)
 */
const Menu: FC<Props> & { Group: typeof Group; Item: typeof Item } = ({
  children,
  renderAnchor,
  displayStatic,
  placement = MenuPlacement.BOTTOM_RIGHT,
  direction = MenuDirection.BOTTOM_LEFT,
  BackdropProps,
  stopPropagation,
  closeOnItemClick = true,
  disablePortal,
  slotConfig = {},
  ...props
}: Props) => {
  const id = useId();
  const {
    shape: { borderRadius },
    spacing,
  } = useTheme();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: id,
  });

  const { groups } = useSlot(children, merge({ groups: Group }, slotConfig));
  const groupsWithDividers = groups.reduce<ReactElement[]>(
    (array, group, groupIndex) =>
      groupIndex === 0
        ? [...array, group]
        : [
            ...array,
            <Divider sx={{ mt: '10px', mb: '5px' }} key={`divider-${groupIndex}-${id}`} />,
            group,
          ],
    [],
  );

  const paperProps = merge(
    {
      sx: {
        border: 'none',
        boxShadow: `inset 0px 0px 0px 1px ${uiColorsV1.grey._600} !important`,
        padding: spacing(3),
        borderRadius: `${borderRadius} !important`,
      } satisfies SxProps,
    } as PaperProps,
    props,
  );

  if (displayStatic) {
    return <Paper {...paperProps}>{groups}</Paper>;
  }

  const triggerState = {
    ...bindTrigger(popupState),
  };

  const popoverOnClick: MouseEventHandler<ElementRef<'div'>> = (e) => {
    stopPropagation && e.stopPropagation();
    closeOnItemClick && popupState.setOpen(false, e);
  };

  return (
    <Fragment>
      {renderAnchor?.(triggerState)}

      <Popover
        {...bindPopover(popupState)}
        {...getOriginFromPlacementAndDirection({ direction, placement })}
        slotProps={{ backdrop: BackdropProps }}
        PaperProps={paperProps}
        onClick={popoverOnClick}
        disablePortal={disablePortal}
      >
        {groupsWithDividers}
      </Popover>
    </Fragment>
  );
};

Menu.Group = Group;
Menu.Item = Item;

export { Menu };
